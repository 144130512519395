// Import styles
import './main.scss';

// Import fonts
import IvarFineLight from './fonts/IvarFine-Light.woff';
import IvarFineLight2 from './fonts/IvarFine-Light.woff2';
import Unica77LLWebItalic from './fonts/Unica77LLWeb-Italic.woff';
import Unica77LLWebItalic2 from './fonts/Unica77LLWeb-Italic.woff2';
import Unica77LLWebRegular from './fonts/Unica77LLWeb-Regular.woff';
import Unica77LLWebRegular2 from './fonts/Unica77LLWeb-Regular.woff2';
import Unica77LLWebMedium from './fonts/Unica77LLWeb-Medium.woff';
import Unica77LLWebMedium2 from './fonts/Unica77LLWeb-Medium.woff2';

// Dependencies
import VanillaTilt from 'vanilla-tilt';
import SelectBox from './components/SelectBox';
import SortTable from './components/SortTable';
import { FlyBy } from './components/FlyBy';

// Helper function for setting cookies
function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  var expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}


//
// Application
//
const CSK = {
  // Options / globals
  init() {
  	VanillaTilt.init();
    this.handleScroll();
    this.initLazyLoad();
    this.initAOS();
    this.initFlickity();
    this.initInfiniteScroll();
    this.initFilter();
    this.initViewFilter();

    const flyByContainer = document.querySelector('.FlyBy');
    if(flyByContainer) {
      new FlyBy(flyByContainer, {
        speed: 0.4
      });
    }

    [...document.querySelectorAll('.introduction')].forEach( el => {
      el.addEventListener(click, evt => {
        el.classList.add('fadeout');
      }, false);
    });

    [...document.querySelectorAll('.meny__toggle')].forEach( el => {
      el.addEventListener('click', evt => {
        document.querySelector(".navigation__list").classList.toggle("show");
        document.querySelector(".meny__toggle svg").classList.toggle("rotate");
        document.querySelector(".navigation__logo span").classList.toggle("show");
        document.querySelector(".navigation").classList.toggle("background");
      }, false);
    });

    [...document.querySelectorAll('.toggle-modal')].forEach( el => {
      el.addEventListener('click', evt => {
        document.querySelector(".modal").classList.toggle("display-modal");
        document.querySelector("body").classList.toggle("overflow-hidden");
      }, false);
    });
  },

  initLazyLoad() {
    new LazyLoad({
      elements_selector: ".lazy"
    });
  },

  initAOS() {
    AOS.init({
      once: true,
      offset: -200
    });
  },

  initInfiniteScroll() {
    // [...document.querySelectorAll('.table__container')].forEach( el => {
    //   new InfiniteScroll( el, {
    //     path: '.pagination__next',
    //     append: '.table__container-item',
    //     history: false
    //   });
    // });
  },

  initFlickity() {
    [...document.querySelectorAll('.gallery')].forEach( el => {
      const test = new Flickity( el, {
        cellAlign: 'left',
        contain: true,
        pageDots: false,
        prevNextButtons: false
      });

      console.log(test);
    });
  },

  initFilter() {
    [...document.querySelectorAll('select')].forEach( el => {
      const box = new SelectBox(el);
      box.listen('change', evt => {
      	document.querySelector(".filter__form-button").classList.toggle('active', evt.detail);
      });
    });

    [...document.querySelectorAll('table[data-sortable]')].forEach(el => {
      new SortTable(el);
    });
  },

  initViewFilter() {
    [...document.querySelectorAll('.ViewToggle')].forEach( el => {
      const target = document.querySelector(el.dataset.target);

      if(!target) {
        return;
      }

      console.log(target);

      el.addEventListener('change', evt => {
        const value = el.elements.viewMode.value;
        setCookie('viewMode', value, 60);

        target.dataset.viewMode = value;
      }, false);
    });
  },

  handleScroll(evt) {
    const logo = document.querySelector('#Logo');
    const navBar = document.querySelector('.navigation__list');
    let prevScrollTop = 0;

    window.addEventListener('scroll', evt => {
      const y = window.pageYOffset;

      // Handle Logo bg color animations
      if(logo) {
        if(y >= 20) {
          logo.classList.add('fadeOut');
          document.body.classList.remove('beige');
        } else {
          logo.classList.remove('fadeOut');
          document.body.classList.add('beige');
        }
      }

      if(navBar) {
        const navOffsetHeight = navBar.offsetHeight;

        if (prevScrollTop < y && y > navOffsetHeight * 2) {
          navBar.classList.add("hidden");
        } else if (prevScrollTop > y && !(y <= navOffsetHeight)) {
          navBar.classList.remove("hidden");
        }

        prevScrollTop = y;
      }

    }, false);
  },

  initOnLoad() {
    console.log('Made by ANTI');
    console.log('https://anti.as');
  }
};

document.addEventListener('DOMContentLoaded', () => CSK.init());
window.addEventListener('load', () => CSK.initOnLoad());

// Accept HMR as per:
// https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
  module.hot.accept();
}
