function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}

function get(url) {
  const requestOptions = {
    method: 'GET'
  };
  return fetch(url, requestOptions).then(handleResponse);
}

class SortTable {
  get apiUrl() {
    return `${this.root_.dataset.prefix}/search.json?${this._params.toString()}`;
  }

  get orderBy () {
    return this._params.get('orderBy');
  }

  set orderBy (value = '') {
    this._params.set('orderBy', value);
  }

  get sortBy () {
    return this._params.get('sortBy');
  }

  set sortBy (value = '') {
    this._params.set('sortBy', value);
  }

  constructor(root) {
    this.root_ = root;
    this.keys_ = [...this.root_.querySelectorAll('th[data-sortable-key]')];
    this.body_ = this.root_.querySelector('tbody');
    this.pagination_ = this.root_.nextElementSibling && this.root_.nextElementSibling.classList.contains('ThePagination') ? this.root_.nextElementSibling.firstChild : null;

    this.isFetchingPage = false;

    this._currentPath = window.location.pathname.split('/page/')[0] || '';
    this._params = new URLSearchParams(window.location.search);

    // Initial active state
    this.currentActive_ = this.keys_.find(el => el.dataset.sorted === 'true');

    this.registerHandlers();
  }

  registerHandlers() {
    // Listners
    this.onKeyClickListener = this.handleKeyClick.bind(this);

    this.keys_.forEach(key => key.addEventListener('click', this.onKeyClickListener, false));
  }

  handleKeyClick(evt) {
    const current = this.currentActive_;

    if (evt.currentTarget === current && this.sortBy === 'DESC') {
      // UNSET
      current.dataset.sorted = 'false';
      current.dataset.sortedDirection = '';
      this.currentActive_ = null;
      this.sortBy = 'ASC';
    } else if (evt.currentTarget === current) {
      current.dataset.sortedDirection = 'descending';
      this.sortBy = 'DESC';
    } else {
      // Unset current
      if(current) {
        current.dataset.sorted = 'false';
        current.dataset.sortedDirection = '';
      }

      this.currentActive_ = evt.currentTarget;
      this.currentActive_.dataset.sorted = 'true';
      this.currentActive_.dataset.sortedDirection = 'ascending';
      this.sortBy = 'ASC';
    }

    this.orderBy = this.currentActive_?.dataset?.sortableKey || '';

    this.update();
  }

  async update() {
    this.isFetchingPage = true;
    this.body_.classList.add('is-updating');

    this._updateUrl();
    const { data = [], meta = null } = await get(this.apiUrl);

    this._updateContent(data);
    this._updatePagination(meta.pagination || null);

    this.body_.classList.remove('is-updating');
    this.isFetchingPage = false;
  }

  _updateUrl() {
    window.history.replaceState({}, "", decodeURIComponent(`${this._currentPath}?${this._params.toString()}`));
  }

  _updateContent(data) {
    while (this.body_.lastElementChild) {
      this.body_.removeChild(this.body_.lastElementChild);
    }

    data.forEach(entry => {
      const row = document.createElement('tr');
      row.classList.add('table__container-item');

      let contents = entry.bildeEllerVideo ? `<td class="item-thumb"><a href="${entry.url}"><img class="lazy" alt="${entry.title}" src="${entry.bildeEllerVideo}" data-src="${entry.bildeEllerVideo}" /></a></td>` : ``;
      contents += `
        <td class="item-name"><a href="${ entry.url }">${ entry.kunstner }</a></td>
        <td class="item-title"><a href="${ entry.url }"><i>${ entry.title }</i></a></td>
        <td class="item-date"><a href="${ entry.url }">${ entry.arstall }</a></td>
      `;

      contents += entry.klassifisering ? `<td class="item-category"><a href="${ entry.url }">${ entry.klassifisering }</a></td>` : ``;
      row.innerHTML = contents;

      this.body_.appendChild(row);
    });
  }

  _updatePagination(pagination) {
    if (!this.pagination_) {
      return;
    }

    const { total_pages = 1 } = pagination;
    const params = this._params.toString();
    const url = this._currentPath;
    const getPageUrl = (n) => `${url}/page/${n}/?${params}`;


    // Remove all existing items
    while (this.pagination_.lastElementChild) {
     this.pagination_.removeChild(this.pagination_.lastElementChild);
    }

    if (total_pages > 1) {
      // Update if has many pages
      let links = '';
      const total = Math.min(5, total_pages);
      for(let i = 0; i < total; i++) {
        const number = i + 1;
        if(number === 1) {
          links += `<li class="is-current">${number}</li>`;
        } else {
          links += `<li><a href="${getPageUrl(number)}">${number}</a></li>`;
        }
      }

      this.pagination_.innerHTML = links;
    }
  }

  /**
   * Wrapper method to add an event listener to the component's root element. This is most useful when
   * listening for custom events.
   */
  listen(evtType, handler, options) {
    this.root.addEventListener(evtType, handler, options);
  }

  /**
   * Wrapper method to remove an event listener to the component's root element. This is most useful when
   * unlistening for custom events.
   */
  unlisten(evtType, handler, options) {
    this.root.removeEventListener(evtType, handler, options);
  }

  /**
   * Fires a cross-browser-compatible custom event from the component root of the given type, with the given data.
   */
  emit(evtType, evtData, shouldBubble = false) {
    let evt;
    if (typeof CustomEvent === 'function') {
      evt = new CustomEvent(evtType, {
        bubbles: shouldBubble,
        detail: evtData,
      });
    } else {
      evt = document.createEvent('CustomEvent');
      evt.initCustomEvent(evtType, shouldBubble, false, evtData);
    }

    this.root_.dispatchEvent(evt);
  }
}

export default SortTable;
